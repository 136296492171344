import './index.css'
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

  export default  function  StreamPlayer() {
    const [stream, setStream] = useState<string>('');
    const {streamId } = useParams();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const matches = useMediaQuery('(min-width: 768px)');

    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    useEffect(() => {
      const link =  `https://sportstransfer.xyz/bongls/?id=${streamId}`;
      setStream(link);
      window.scrollTo(0, 0);
      window.addEventListener('resize', updateScreenSize);
      return () => window.removeEventListener('resize', updateScreenSize);
    }, []);
  
    return (
      <>
      {
      stream &&
        <Row gutter={24} style={{display:'flex', justifyContent: 'center', margin: '5px'}}>
          <Col span={24} style={{display:'flex', justifyContent: 'center'}}>
          {!stream && <div className='loading-placeholder'>Loading...</div>}
          <iframe
          style={{overflow: 'hidden'}}
          title="Video Player"
          width={screenWidth}
          height={matches ? screenHeight: screenHeight/2.5}
          min-height={'250vh'}
          src={stream}
          frameBorder="no"
          scrolling="no"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
          {/* <iframe src={url} allow="autoplay; fullscreen"  frameBorder="no" scrolling="no"  ></iframe>           */}
          </Col>
        </Row>
      }
      </>
    );
  }
  